<template>
  <div class="content items-center">
    <Mural
      class="q-mt-md"
      style="width: 97%"
      :breadcrumbs="breadcrumbs"
      route="training-page"
      :query="{
        courseId: course_id,
        trainingId: training_id,
      }"
    ></Mural>
    <section class="row justify-between q-gutter-md q-my-md">
      <div class="container-card col q-px-md">
        <h2 class="information-label">Curso</h2>
        <p class="information">
          {{ informations.course_title }}
        </p>
      </div>
      <div class="container-card col q-px-md">
        <h2 class="information-label">Aluno</h2>
        <p class="information">{{ student_name }}</p>
      </div>
      <div class="container-card col q-px-md">
        <h2 class="information-label">Nota</h2>
        <p class="information">
          {{ informations.score || 0.0 }}/{{ informations.max_score }}
        </p>
      </div>
    </section>
    <section
      class="q-mx-xl flex column no-wrap scroll container-correction scroll"
      style="height: 500px"
    >
      <div v-for="(question, index) in questions" :key="index">
        <ViewCorrectionQuestions
          :question="question"
          :informations="informations"
          :index="index"
        />
      </div>
    </section>
  </div>
</template>

<script>
/* Components */
import Mural from "@/components/Mural.vue";
import ViewCorrectionQuestions from "@/components/courses/others/ViewCorrectionQuestions.vue";
/* VUE */
import { ref, onMounted, computed } from "vue";

/* Router */
import { useRoute, useRouter } from "vue-router";

/* VueEx */

import { useStore } from "vuex";

/* Services */
import TrailDataServices from "@/services/TrailDataServices";

/* Utils */

import AlertsClass from "@/utils/Notifications/AlertsClass";

export default {
  name: "ViewFixedQuestions",
  components: {
    Mural,
    ViewCorrectionQuestions,
  },
  setup(props) {
    /* Constants */
    const _TrailDataServices = new TrailDataServices();
    const route = new useRoute();
    const router = new useRouter();
    const store = useStore();

    const course_id = route.query.course_id;
    const training_id = route.query.training_id;
    const quizStudent_id = route.query.quizStudent_id;
    const quiz_id = route.query.quiz_id;
    const title = route.query.title;
    const breadcrumbs = ref([`Correção - ${title}`]);
    const questions = ref([]);
    const informations = ref({});
    const Alerts = new AlertsClass();
    /* Computed */
    const student_name = computed(
      () =>
        `${store.state.userdata.first_name} ${store.state.userdata.last_name}`
    );

    async function getQuizAfterCorrection() {
      await _TrailDataServices
        .ViewFixedQuestions(quizStudent_id)
        .then((response) => {
          const json_questions = JSON.parse(response.data[0].json_question);
          questions.value = json_questions;
          informations.value = {
            course_title: response.data[0].course_title,
            score: response.data[0].score,
            max_score: response.data[0].max_score.toFixed(2),
          };

          hasUnrevisedQuestions(questions.value);
        })
        .catch((error) => {});
    }

    function hasUnrevisedQuestions(questions) {
      let validation;
      questions.find((question) => {
        if (question?.review == null && question.option_type == 3)
          validation = true;
      });
      if (validation) {
        Alerts.alertOf(
          "A sua prova ainda não foi corrigida! Por favor aguarde ou entre em contato com o seu tutor"
        );
        setTimeout(() => {
          router.push({
            name: "training-page",
            query: {
              courseId: course_id,
              trainingId: training_id,
            },
          });
        }, 7000);
      }
    }

    onMounted(() => {
      getQuizAfterCorrection();
    });

    return {
      breadcrumbs,
      questions,
      student_name,
      course_id,
      training_id,
      informations,
    };
  },
};
</script>

<style lang="scss" scoped>
.content{
  overflow: auto;
  height: 95%;
  display: flex;
  flex-direction: column;
  padding: 0 24px!important;
}
.information-label {
  font-size: 1rem;
  letter-spacing: -0.25px;
  color: #1a1a1a;
  font-weight: 600;
}

.information {
  font-size: 0.75rem;
  color: #808080;
  letter-spacing: -0.25px;
  font-weight: 500;
}

.container-card {
  min-width: 200px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.container-correction {
  width: 90%;
}
</style>
