<template>
  <div>
    <q-expansion-item
      expand-separator
      expand-icon-toggle
      switch-toggle-side
      class="q-mt-md"
      :header-style="{ width: '100%', fontWeight: 'bold' }"
      :default-opened="index == 0"
    >
      <template v-slot:header>
        <div class="flex items-baseline" style="width: 100%">
          <h2>Questão {{ index + 1 }}</h2>
          <div class="q-ml-md flex items-baseline">
            <label class="q-mr-xs">nota</label>
            <q-input
              dense
              v-model="score"
              step="any"
              placeholder="0.00"
              input-class="text-center"
              filled
              readonly
            />
          </div>
          <div class="q-ml-md flex items-baseline">
            <label class="q-mr-xs">peso</label>
            <q-input
              dense
              :placeholder="weight"
              type="number"
              filled
              readonly
            />
          </div>
        </div>
      </template>
      <div class="q-px-md q-mt-xs" v-show="question.option_type == 3">
        <p><strong>Pergunta:</strong> {{ question.description_question }}</p>
        <p class="answer">
          <strong>Resposta do aluno:</strong> {{ question.answers }}
        </p>
        <div>
          <label class="correction" for="">Correção da questão</label>
          <q-input
            :hint="correctedBy"
            v-model="review"
            type="textarea"
            outlined
            class="q-mt-md"
            readonly
          >
          </q-input>
        </div>
      </div>
      <div class="q-px-md q-mt-xs" v-show="question.option_type == 2">
        <p><strong>Pergunta:</strong> {{ question.description_question }}</p>
        <div class="row">
          <div class="column col-2">
            <p class="answer"><strong>Resposta do aluno</strong></p>
            <div
              v-for="(
                { description_option, correct_question }, index
              ) in question.questions_options"
              :key="index"
            >
              <div>
                <q-checkbox
                  right-label
                  color="black"
                  :label="description_option"
                  readonly
                  :model-value="correct_question"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <p class="answer"><strong>Gabarito</strong></p>
            <div
              v-for="(
                { description_option, selected_user }, index
              ) in question.questions_options"
              :key="index"
            >
              <div>
                <q-checkbox
                  right-label
                  :color="selected_user ? 'green' : 'red'"
                  :label="description_option"
                  readonly
                  :model-value="selected_user"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="q-px-md q-mt-xs" v-show="question.option_type == 1">
        <p><strong>Pergunta:</strong> {{ question.description_question }}</p>
        <div class="row">
          <div class="column col-2">
            <p class="answer"><strong>Resposta do aluno</strong></p>
            <div
              v-for="(
                { description_option, selected_user }, index
              ) in question.questions_options"
              :key="index"
            >
              <div>
                <q-checkbox
                  :model-value="selected_user"
                  :label="description_option"
                  color="black"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="column">
            <p class="answer"><strong>Gabarito</strong></p>
            <div
              v-for="(
                { description_option, correct_question, questionOptionId },
                index
              ) in question.questions_options"
              :key="index"
            >
              <div>
                <q-checkbox
                  :model-value="correct_question"
                  :val="questionOptionId"
                  :label="description_option"
                  :color="correct_question ? 'green' : 'red'"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </q-expansion-item>
  </div>
</template>

<script>
/* VUE */
import { ref, computed } from "vue";

export default {
  name: "ViewCorrectionQuestions",
  props: {
    question: {
      type: Object,
      required: true,
    },
    informations: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    /* CONSTANTS */
    const score = props.question.score?.toFixed(2);
    const review = props.question.review;
    const date = new Date(props.question.review_at).toLocaleDateString();

    /* COMPUTEDS */
    const weight = computed(() => props.informations.max_score);
    const correctedBy = computed(
      () => `Corrigido por: ${props.question.review_by} em ${date}`
    );
    /*     const questionMaxScore = computed(() => props.question.question_score_max);
     */

    return {
      /* COMPUTEDS */
      weight,
      correctedBy,
      /* CONSTANTS */
      review,
      score,
    };
  },
};
</script>

<style scoped>
h2 {
  font-size: 0.875rem;
  font-weight: bold;
}

label {
  color: #808080;
  letter-spacing: -0.25px;
  font-weight: 500;
  font-size: 0.875rem;
}

p {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 20px;
  width: 90%;
  color: #666666;
}

p.answer {
  font-weight: 500;
  color: #a6a6a6;
}

.correction {
  font-weight: 600;
}

.q-checkbox__bg {
  border-radius: 10px !important;
}
</style>
